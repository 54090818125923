import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0df9b546"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scanner" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "product-info" }
const _hoisted_4 = { class: "overline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Scan order items")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createElementVNode("main", null, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.translate('Scan items'),
                  "label-placement": "fixed",
                  autofocus: "",
                  placeholder: _ctx.translate('Scan barcodes to receive them'),
                  modelValue: _ctx.queryString,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
                  onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.updateProductScannedStatus()), ["enter"]))
                }, null, 8, ["label", "placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              expand: "block",
              fill: "outline",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scan()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.cameraOutline
                }, null, 8, ["icon"]),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("Scan")), 1)
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderItems, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["list-item", item.orderItemSeqId === _ctx.lastScannedId ? 'scanned-item' : '' ]),
              key: index,
              id: item.productSku
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxpShopifyImg, {
                          src: _ctx.getProduct(item.productId).mainImageUrl,
                          size: "small"
                        }, null, 8, ["src"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : item.productName) + " ", 1),
                        (_ctx.isKit(item))
                          ? (_openBlock(), _createBlock(_component_ion_badge, {
                              key: 0,
                              color: "dark"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/')), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    disabled: "",
                    checked: item.isChecked
                  }, null, 8, ["checked"])
                ]),
                _: 2
              }, 1024)
            ], 10, _hoisted_2))
          }), 128))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_fab, {
      vertical: "bottom",
      horizontal: "end",
      slot: "fixed"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, {
          disabled: !_ctx.areAllItemsSelected(),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.packOrder()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}